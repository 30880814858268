//Settings pages styles
.settings {
	&.team {
	  .card.team-item {
		box-shadow: #{map_get($shadows, 3)};
		margin-bottom: 0;
		height: 100%;
  
		.card-footer {
		  background-color: $white;
		  border-top: none;
  
		  button.text-gray {
			color: $color-fan-gray-title;
		  }
		}
	  }
	}
  }
  