.finance-fee {
	.gray-block {
		background-color: rgba(154,165,177,0.1);
		padding: _rem(10);
		border-radius: _rem(4);

		.value {
			margin-top: _rem(8);
			color: $color-fan-gray-title;
		}
	}
}