//Get relative size
@function _rem($px-value, $root-em: 1rem) {
  @return $px-value / 16 * $root-em;
}

@mixin boxShadow($variant) {
  box-shadow: #{map-get($shadows, $variant)};
  -webkit-box-shadow: #{map-get($shadows, $variant)};
  -moz-box-shadow: #{map-get($shadows, $variant)};
}

@mixin fontAvertaLight() {
  font-family: "Averta Light" !important;
  font-weight: 300;
}

@mixin fontAvertaRegular() {
  font-family: "Averta Regular" !important;
  font-weight: normal;
}

@mixin fontAvertaSemibold() {
  font-family: "Averta Semibold" !important;
  font-weight: bold;
}

@mixin fontAvertaBold() {
  font-family: "Averta Bold" !important;
  font-weight: bolder;
}

@mixin fontAvertaItalic() {
  font-family: "Averta Regular Italic" !important;
}

@mixin fontAvertaSemiBoldItalic() {
  font-family: "Averta SemiBold Italic" !important;
}

@mixin fontAverta() {
  font-family: "Averta", sans-serif !important;
  font-weight: normal;
}

@mixin fontGothamNormal() {
  font-family: "Gotham", sans-serif !important;
  font-weight: normal;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin hover() {
  &:hover {
    @content;
  }
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

//for safari because it doesn't display GothamBold
@mixin gothamBoldAlternative() {
  font-family: "GothamBold", sans-serif !important;
}


@mixin fp-media-breakpoint-down($width) {
  @media (max-width: #{$width}) {
    @content;
  }
}

@mixin fp-media-breakpoint-up($width) {
  @media (min-width: #{$width}) {
    @content;
  }
}

@mixin fp-media-down-lg() {
  @include fp-media-breakpoint-down(map_get($grid-breakpoints, 'lg')) {
    @content;
  }
}
