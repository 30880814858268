/* Image gallery styles */
ul.image-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 _rem(-5);

  li {
    display: flex;
    margin: 0 _rem(5) _rem(10) _rem(5);
    width: _rem(70);
    min-width: _rem(70);
    height: _rem(70);
    background-color: $color-cco-border;

    &.add-new-item {
      cursor: pointer;
      align-items: center;
      justify-content: center;
      background-color: $color-cco-background;
      border: 1px dashed $color-cco-placeholder;
    }
  }
}