/* Editor styles */
.editor-wrapper {
  background-color: $color-cco-white;
  border: 1px solid $color-cco-gray-background2;
  border-radius: _rem(8);
  padding: _rem(20) 0 0 0;

  textarea {
    width: 100%;
    border: none;
    resize: none;
    border-radius: _rem(8);
    padding-left: _rem(20);
    padding-right: _rem(20);

    &::placeholder {
      color: $color-cco-placeholder;
    }

    &:focus {
      outline: none;
    }
  }

  .buttons-toolbar {
    display: flex;
    align-items: center;
    padding: _rem(10) _rem(20) _rem(10) _rem(12);
    border-top: 1px solid $color-cco-border;
    min-height: _rem(35);

    .MuiIconButton-root {
      margin: 0 _rem(5);

      .MuiSvgIcon-root {
        width: auto;
      }
    }

    .separator {
      position: relative;
      margin: 0 _rem(5);
      height: _rem(15);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 15px;
        width: 1px;
        background-color: #ECECEA;
      }
    }
  }
}