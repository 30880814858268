//Details page
.details-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  .top-block {
    flex: 0 1 auto;
    border-bottom: 0.64px solid $color-cco-border;
    box-shadow: 0 5px 10px 0 rgba(14, 19, 30, 0.02);

    .action-buttons {
      > * {
        margin-left: _rem(15);

        &:first-child {
          margin-left: 0;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  //Top block (with avatar and actions)
  .details-header {
    padding: _rem(50) _rem(35) 0 _rem(35);

    @media (max-width: 1340px) {
      padding-left: _rem(15);
      padding-right: _rem(15);
    }

    @include media-breakpoint-down(md) {
      padding: _rem(5) _rem(15) 0 _rem(15);

      h3 {
        margin-top: _rem(-28);
        font-size: _rem(20);
        margin-bottom: _rem(16);
      }
    }

    &.job-details-header {
      @include media-breakpoint-down(md) {
        padding-top: _rem(10);
      }

      .title-block {
        .MuiButtonBase-root {
          min-width: auto;
        }
      }
    }

    &.sale-details-header {
      @include media-breakpoint-up(lg) {
        padding-top: _rem(40);
        padding-bottom: _rem(40);
      }
    }

    .title-block {
      padding-bottom: _rem(45);

      .details-header-part {
        .editable-avatar {
          position: relative;

          .edit-icon-button {
            position: absolute;
            right: 0;
            bottom: 0;

            .MuiSvgIcon-root {
              width: 12px;
              height: 12px;
            }
          }

          .delete-icon-button {
            position: absolute;
            display: none;
            top: 0;
            left: 0;

            .MuiSvgIcon-root {
              width: 12px;
              height: 12px;
            }
          }
          &:hover, &:focus {
            .delete-icon-button {
              display: inline-block;
            }
          }
        }

        .information-part {
          padding-left: _rem(20);

          .social-links-toolbar {
            margin-left: _rem(10);

            .social-link {
              margin: 0 _rem(4);
            }
          }

          .subelements {
            padding-top: _rem(5);

            .uid {
              padding-right: _rem(7);
            }
          }

          @include media-breakpoint-down(md) {
            padding: _rem(15) 0;
          }
        }
      }
    }

    .MuiTabs-root {
      margin-bottom: 0;
    }

    .user-details-head-row {
      margin: 0 _rem(-10);
      .user-details-head-item {
        display: flex;
        align-items: center;
        margin: 0 _rem(10);

        button, span {
          font-size: _rem(14) !important;
        }
      }

      @include media-breakpoint-down(md) {
        .user-details-head-item + .user-details-head-item {
          padding-top: _rem(10);
          padding-left: _rem(5);
        }
      }
    }
  }

  //Tabs content
  .content-block {
    padding: _rem(40) _rem(35) _rem(104) _rem(35);

    @media (max-width: 1340px) {
      padding: _rem(40) _rem(15) _rem(104) _rem(15);
    }

    @include media-breakpoint-down(md) {
      padding: _rem(30) 0;
    }
  }

  //Tab pane two rows
  .tab-pane {
    margin: 0 _rem(-10);

    .row.g-0 {
      [class*="col-"] {
        .card.details-card {

          @include media-breakpoint-down(md) {
            height: auto !important;
          }
        }
      }
    }

    .search-tags-wrapper {
      margin: _rem(10) _rem(-5);
    }
  }

  .reference-available {
    .MuiFormGroup-root {
      .MuiFormControlLabel-root {
        margin-bottom: 0;
      }
    }
  }

  .week-availability-item {
    padding: 0 _rem(10) 0 0;

    @include media-breakpoint-down(md) {
      padding: 0 0 _rem(10) 0;
    }
  }

  //Tab content fade effect
  .tab-content {
    .tab-pane.active {
      animation-name: fadeIn;
      animation-duration: 300ms;
      animation-timing-function: linear;
    }
  }

  &.product {
    .content-block {
      padding: _rem(40) _rem(35) _rem(50);

      @media (max-width: 1340px) {
        padding-left: _rem(15);
        padding-right: _rem(15);
      }
      
      @media (max-width: 1200px) {
        padding: _rem(40) _rem(16) _rem(50);
      }

      .details-card.variants {
        @include media-breakpoint-up(lg) {
          margin-top: _rem(20);
        }
      }

      .product-variant {
        padding-bottom: _rem(26);
        margin-bottom: _rem(20);
        border-bottom: 1px solid $color-cco-border;
      }
    }
  }

  &.user {
    span.mark {
      background-color: unset;
      padding: 0;
      .MuiSvgIcon-root {
        width: _rem(20);
        height: _rem(20);
      }
    }
  }
}