.chart-doughnut {
	position: relative;
	width: _rem(138);
	height: _rem(138);

	@include media-breakpoint-down(lg) {
		margin: 0 auto rfs-value(30px);
	}

	.chart-title {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		@include fontAvertaSemibold();
		font-size: _rem(30);
		line-height: _rem(37);
	}
}