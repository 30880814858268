/* Timepicker styles */
.timer-dialog {
	.MuiPopover-paper {
		box-shadow: #{map_get($shadows, 1)};

		.MuiPickersBasePicker-container {
			.MuiPickersToolbar-toolbar {
				height: auto;
				min-height: unset;
				padding: _rem(10) 0;

				.MuiPickersTimePickerToolbar-hourMinuteLabel {
					h2 {
						font-size: _rem(30);
					}
				}
			}
		}
	}
}