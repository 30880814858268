/* Photo List editor component */
.photo-editor {
  .editor-content {
    padding: 0 _rem(50);

    .header {
      padding-bottom: _rem(43);

      .undo-btn {
        .MuiButton-label {
          .MuiButton-startIcon {
            margin-right: _rem(7);

            .MuiSvgIcon-root {
              color: $color-cco-placeholder;
            }
          }

          font-size: _rem(12);
          @include fontAvertaSemibold();
          color: $color-cco-placeholder;
        }

        &:hover {
          text-decoration: none;
          .MuiButton-label {
            color: $color-cco-dark;

            .MuiButton-startIcon {
              margin-right: _rem(7);

              .MuiSvgIcon-root {
                color: $color-cco-dark;
              }
            }
          }
        }
      }
    }

    .content {
      padding-bottom: _rem(50);
      .img-wrapper {
        position: relative;
        padding-bottom: _rem(22);

        .MuiIconButton-root {
          position: absolute;
          top: _rem(15);
          right: _rem(16);
          margin: 0;

          .MuiSvgIcon-root {
            width: auto;
          }

          &:hover {
            svg path {
              fill: currentColor;
            }
          }
        }

        .MuiSvgIcon-root {
          display: none;
        }

        &:hover {
          .MuiSvgIcon-root {
            display: flex;
          }
        }
      }

      .editor-tools {
        .icon-buttons {
          padding-bottom: _rem(24);

          .MuiIconButton-root {
            margin: 0 _rem(17);

            &.Mui-disabled {
              .MuiSvgIcon-root {
                opacity: 0.25;
              }
            }
          }
        }
      }

      .editor-toolbar {
        display: flex;
        padding-bottom: _rem(25);
        overflow: hidden;

        .MuiButtonBase-root {
          flex: 1 0 auto;
          color: $color-cco-dark;
        }

        .MuiButtonBase-root + .MuiButtonBase-root {
          margin-left: _rem(11);
        }
      }

      .alt-text-legend {
        padding-bottom: _rem(5);
      }

      .small-label {
        padding-bottom: _rem(5);
      }
    }
  }
}