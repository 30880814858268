//Sales pages
.card.details-card.sales-details {

	.icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: _rem(35);
		height: _rem(35);
		border-radius: _rem(4);

		&.green {
			background-color: rgba(33,181,155,0.1);
		}

		&.blue {
			background-color: rgba(51,133,204,0.1);
		}

		&.orange {
			background-color: rgba(255,129,69,0.1);
		}
	}

	> .card-body {
		padding: _rem(15) _rem(30) _rem(30) _rem(75);

		@include media-breakpoint-down(lg) {
			padding: _rem(16);
		}
	}
}

.card.details-card.sales-summary {
	> .card-body {
		padding-bottom: _rem(25);
	}
}

.document-preview-row {
	padding: _rem(10) 0;

	@include media-breakpoint-up(xl) {
		margin-right: _rem(45);
	}

	&:last-child {
		padding-bottom: 0;
	}
}

.document-preview-row + .document-preview-row {
	border-top: 1px solid $color-cco-border;
}

.refurd-popup-form {
	.edit-wrapper {
		@include media-breakpoint-up(lg) {
			max-width: _rem(108);
		}
	}
}

.details-page {
	&.sale {
		.content-block {
			.MuiAvatar-root {
				> p {
					@include fontAvertaBold();
					font-size: _rem(14) !important;
					padding-top: _rem(7);
				}
			}
		}
	}
}