/* Messaging styles */
.messaging-container {
  flex: 1 1;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .conversations-list {
    flex: 1;
    background-color: $color-cco-white;
    padding-top: _rem(32);
    width: 40%;
    max-width: _rem(370);

    @include media-breakpoint-down(lg) {
      max-width: _rem(315);
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: unset;
      padding-bottom: _rem(50);
    }

    .actions-row {
      padding: 0 _rem(30);
      .select-wrapper {
        width: 33%;
      }

      .MuiButtonBase-root {
        .MuiButton-label {
          font-size: _rem(14);
        }
      }
    }

    .search-field-wrapper {
      padding: _rem(22) _rem(30) _rem(30) _rem(30);
    }

    //Last Message Item
    .sender-last-message {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      cursor: pointer;
      padding: _rem(20) _rem(30);
      border-top: 1px solid $color-cco-gray-light;

      &.active {
        background-color: $color-cco-background;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: _rem(5);
          background-color: $color-cco-green;
        }

        .sender-name {
          @include fontAvertaSemibold();
        }
      }

      .message {
        width: 70%;

        .message-content {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-height: _rem(15);        /* fallback */
          max-height: _rem(30);       /* fallback */
        }
      }
    }
  }

  .conversation-field {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    padding: _rem(40) _rem(50);

    @include media-breakpoint-down(md) {
      padding: _rem(40) _rem(20) 0 _rem(20);
    }

    .conversation-header {
      display: flex;
      align-items: center;
      padding-bottom: _rem(10);
    }

    .conversation-area {
      flex: 1 0;
      overflow-y: hidden;

      .conversation-chunk {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: _rem(10) 0 _rem(5) _rem(0);

        .message-item {
          display: flex;
          align-items: flex-start;
          align-self: flex-start;
          padding-bottom: _rem(10);
          max-width: 50%;

          @include media-breakpoint-down(lg) {
            max-width: 70%;
          }

          @include media-breakpoint-down(md) {
            max-width: 85%;
          }

          .preview-wrapper {
            width: _rem(213);
            height: _rem(104);
            border-radius: _rem(8);
            background-color: $color-cco-white;
            margin-top: _rem(5);
            border: 1px solid $color-cco-border;
          }

          .message-wrapper {
            background-color: $color-cco-gray-background;
            color: $color-cco-placeholder;
            padding: _rem(9) _rem(15);
            border-radius: 0 _rem(8) _rem(8) _rem(8);
            margin-left: _rem(18);
          }

          &.out {
            align-self: flex-end;
            padding-bottom: _rem(5);

            .message-wrapper {
              background-color: $color-cco-green;
              color: $color-cco-white;
              border-radius: _rem(8) _rem(8) 0 _rem(8);
              margin-right: _rem(7);
            }

            .preview-wrapper {
              margin-right: _rem(7);
            }
          }
        }
      }
    }

    .editor-block {
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      padding-top: _rem(20);
      padding-bottom: _rem(50);

      .editor-wrapper {
        margin-bottom: _rem(20);
      }
    }
  }
}