/* Responsive table */
.table {
  margin-bottom: 0;

  th, td {
    border: none !important;
    vertical-align: bottom;
  }

  tr {
    th, td {
      padding: _rem(12) _rem(15);
    }

    @include media-breakpoint-up(md) {
      th, td {
        &:first-child {
          padding-left: 0;
        }
      }

      th, td {
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .no-more-tables table,
  .no-more-tables thead,
  .no-more-tables tbody,
  .no-more-tables th,
  .no-more-tables td,
  .no-more-tables tr {
    display: block;
    padding: 0;
  }

  table.no-more-tables[style] {
    width: 100% !important;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .no-more-tables tr {
    td {
      &:first-child {
        border-bottom: 2px solid $gray-600 !important;
      }
    }
  }

  .no-more-tables td {
    /* Behave  like a "row" */
    position: relative;
    padding-left: 50%!important;
    padding-right: 0 !important;
    white-space: normal;
    text-align: left;
    min-height: _rem(30);

    &.is-title {
      padding-left: _rem(36) !important;
    }
  }

  .no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 45%;
    padding-right: _rem(10);
    white-space: nowrap;
    text-align: left;
    font-weight: 400;
  }

  /*
  Label the data
  */
  .no-more-tables td:before {
    content: attr(data-title);
  }

  .no-more-tables table {
    margin-bottom: _rem(20);
  }
}