//Dropzone
.dropzone {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	border: 1px dashed $color-cco-placeholder;
	background-color: $color-cco-background;
	padding: _rem(25) _rem(15);

	&.venue-image {
		@include media-breakpoint-up(lg) {
			min-height: _rem(120);
		}
	}

	.add-file-link {
		@include fontAvertaSemibold();
		font-size: _rem(12);
		line-height: 1.25;
		text-decoration: none;
		color: $color-cco-muted;

		&:hover {
			text-decoration: underline;
		}
	}
}

.files-list {
	.file-info {
		display: flex;
		align-items: center;
		padding-top: _rem(10);
		padding-right: _rem(3);
		overflow: hidden;

		.file-preview {
			width: _rem(30);
			height: _rem(30);
			margin-right: _rem(7);
		}

		.file-with-upload-status {
			flex: 1 0;
			overflow: hidden;
		}
	}
}