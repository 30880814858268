/* Counter styles */
.counter {
  display: inline-flex;
  align-items: center;
  .input-group {
    flex: 0 1 _rem(95);
    padding: _rem(6) 0;
    background-color: $color-cco-background;
    border-radius: _rem(4);
    justify-content: space-between;

    .form-control {
      border: none;
      flex: 0 1 _rem(35);
      padding: _rem(5) _rem(2);
      text-align: center;
      border-radius: _rem(4);
      font-size: _rem(14);

      &:focus {
        box-shadow: none;
        outline: none;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
      }
    }

    .input-group-append,
    .input-group-prepend {
      display: flex;
      align-items: center;
      justify-content: center;
      width: _rem(30);
    }
  }

  .max-count {
    min-width: _rem(35);
  }
}