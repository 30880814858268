.permission-management {
	padding-bottom: _rem(50);

	.top-column-left {
		padding-bottom: _rem(20);
	}

	@include media-breakpoint-up(lg) {
		.top-column-left {
			background-color: $white;
			padding: _rem(20) _rem(40) 0 _rem(50);
		}

		.top-column-right {
			padding: _rem(35) _rem(50) 0 _rem(15);
		}
	}

	.role-list {
		display: flex;
		flex: 1 0;
	}

	.role-header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-fan-header;
		padding: _rem(10);
		min-height: _rem(40);
		border-color: $color-cco-border;
		border-width: 1px;
		border-style: none solid;

		&.active {
			background-color: $color-fan-header-active;
			border: 1px solid $color-fan-header-active-border;
		}

		.actions-menu {
			position: absolute;
			right: _rem(10);
			top: _rem(13);

			.dropdown-menu {
				min-width: _rem(122);
				&.show {
					margin-top: _rem(15);
				}
			}
		}

		.role-name {
			text-transform: capitalize;
			@include fontAvertaSemibold();
			&.editable {
				outline: none;
			}
		}
	}

	.role-cell {
		text-align: center;
		border-bottom: 1px solid $color-cco-border;

		&.main {
			background-color: $color-fan-cell-main;
		}

		.MuiCheckbox-root {
			padding: _rem(7);
		}
	}

	.role-cell + .role-cell {
		border-left: 1px solid $color-cco-border;
	}

	.right-cell {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		background-color: $white;
		padding: _rem(9) _rem(5) _rem(9) _rem(50);
		border-bottom: 1px solid $color-cco-border;

		@include media-breakpoint-down(lg) {
			padding: _rem(9) _rem(5);
		}

		&.main {
			background-color: transparent;
			cursor: pointer;
			@include media-breakpoint-up(lg) {
				padding-right: _rem(22);
			}
		}
	}
}