/* Custom dropdown styles */
.custom-dropdown {
  display: flex;

  .dropdown-toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: _rem(20);
    cursor: pointer;
    user-select: none;
    overflow: hidden;

    &.custom-menu-icon {
      padding-right: 0;
      &:after {
        display: none;
      }

      .MuiIconButton-label {
        margin-left: _rem(15);
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      border: none;
      margin: 0;
      vertical-align: unset;
      right: 0;
      bottom: _rem(1);
      width: _rem(11);
      height: _rem(11);
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 6' aria-hidden='true'%3E%3Cg id='Filter/Location' transform='translate(-191.000000, -246.000000)' fill='%23282B3E' stroke='%23282B3E'%3E%3Cpath d='M197.893917,248.776407 L193.609399,245.086317 C193.467558,244.968335 193.241532,244.971723 193.104545,245.093885 C192.970926,245.213056 192.970926,245.401975 193.104545,245.521128 L197.136658,248.993813 L193.104545,252.466497 C192.965152,252.586569 192.965152,252.781254 193.104545,252.901326 C193.24398,253.021362 193.470005,253.021362 193.609399,252.901326 L197.893917,249.211218 C198.033311,249.091128 198.033311,248.896479 197.893917,248.776407' id='Fill-1' transform='translate(195.499231, 248.995677) scale(-1, 1) rotate(-270.000000) translate(-195.499231, -248.995677) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }

    &[aria-expanded="true"] {
      &:after {
        transform: rotate(180deg);
        bottom: _rem(5);
      }
    }
  }

  .dropdown-menu {
    margin: _rem(9) 0 0;
    padding: _rem(12) 0;
    border: none;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
    border-radius: _rem(8);

    .dropdown-item {
      padding: _rem(8) _rem(20);

      span {
        font-size: _rem(14);
        line-height: normal;
      }

      &:hover {
        background-color: $color-cco-border-light;
      }

      &:active {
        color: inherit;
      }
    }
  }
}