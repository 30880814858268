//Styles for notistack notification
.fan-notification-top-right {
  @include media-breakpoint-up(lg) {
    top: 40px !important;
    right: 43px !important;
  }
}

.right-modal-open .fan-notification-top-right {
  @include media-breakpoint-up(lg) {
    right: 400px !important;
  }
}

.fan-notification-root {
  > div {
    @media (max-width: 599.95px) {
      width: 100%;
    }
  }
}
.fan-notification {
  @include fontAvertaRegular();
  background-color: $color-cco-white;
  width: _rem(335);
  min-height: _rem(70);
  padding: _rem(15) _rem(20) _rem(15) _rem(15);
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  border-radius: _rem(8);
  margin: 2px 0;
  @media (max-width: 599.95px) {
    width: 100%;
  }

  .title {
    font-size: _rem(18);
    @include fontAvertaSemibold();
    margin-bottom: 0;
    text-transform: capitalize;
  }
  .message {
    font-size: _rem(14);
    color: $color-cco-muted;
    margin-bottom: 0;
  }
  .close-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &.error {
    border-left: 10px solid $color-cco-danger;
  }

  &.success {
    border-left: 10px solid $color-cco-green;
  }

  &.warning {
    border-left: 10px solid $color-fan-warning;
  }

  &.fan-notification-full-width {
    width: 1280px;
    text-align: center;
    padding: 20px 10px 20px 15px;
    
    @media (max-width: 1260px) {
      width: unset;
    }

    @media (max-width: 576px) {
      margin: 0 16px;
    }

    &.info {
      color: $color-cco-white;
      background-color: $color-cco-green;
    }

    .title {
      font-weight: 700;
    }

    .message {
      font-weight: 200;
    }
  }
}