/* Fonts */
@font-face {
  font-family: 'Averta Regular';
  src: url("../fonts/Averta-Regular.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta Semibold';
  src: url("../fonts/Averta-Semibold.otf") format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Averta Bold';
  src: url("../fonts/Averta-Bold.otf") format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Averta Light';
  src: url("../fonts/Averta-Light.otf") format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Averta Regular Italic';
  src: url("../fonts/Averta-Regular-Italic.otf") format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Averta SemiBold Italic';
  src: url("../fonts/Averta-SemiboldItalic.otf") format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: normal;
  src: local('gotham-book'), url('../fonts/gotham-book.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamBold';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('GothamBold'), url('../fonts/Gotham-Bold.woff') format('woff');
}
