//Cards list
.cards-list {
  .card {
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
    border-radius: _rem(8);
    margin: _rem(10);
    height: calc(100% - 20px);

    @include media-breakpoint-down(md) {
      min-height: unset;
    }

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      background-color: $color-cco-white;
      border-radius: _rem(8) _rem(8) 0 0;
      padding: _rem(20) _rem(15) 0 _rem(20);

      .btn-toolbar {
        display: flex;
        justify-content: flex-end;
        .MuiButtonBase-root + .MuiButtonBase-root {
          margin-left: _rem(16);
        }
      }
    }

    .card-body {
      padding: _rem(20) _rem(20);

      .item-row {
        padding-bottom: _rem(10);
      }
    }

    &.add-new {
      cursor: pointer;
      border: 1px dashed $color-cco-gray-background2;

      &:before, &:after {
        display: none;
      }

      &:hover {
        background-color: transparent;
      }

      .inner {
        margin: auto 0;
        color: $color-cco-placeholder;

        svg {
          font-size: 85px;
        }

        .MuiBox-root {
          padding-bottom: _rem(15);
          font-size: _rem(20);
        }
      }

      @include media-breakpoint-down(md) {
        background-color: transparent;
        .inner {
          padding: _rem(20);

          svg {
            font-size: 50px;
          }

          .MuiBox-root {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.edit-address-checkbox-row {
  margin-bottom: _rem(5);

  .MuiFormControlLabel-root {

    .MuiIconButton-colorSecondary:hover {
      background-color: transparent;
    }
  }
}