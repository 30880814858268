.user-activity {

	.card {
		&.metrics-card {
			.card-body {
				padding-bottom: rfs-value(22px);
			}
		}
	}

	.order-list-box-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: rfs-value(13px) rfs-value(20px);
		border-radius: $border-radius;
		background-color: $color-fan-bg-violet;
	}

	table.striped-table {
		border-spacing: 0 rfs-value(8px);
		border-collapse: separate;
		thead {
			th {
				padding: 0 rfs-value(20px) rfs-value(10px);
				border: none;

				@include media-breakpoint-down(lg) {
					padding: 0 rfs-value(15px) rfs-value(10px);
				}
			}
		}

		&[aria-label="order-list"],
		&[aria-label="sales-breakdown"] {
			thead th:first-child {
				@include media-breakpoint-down(lg) {
					width: 50%;
				}
			}
		}

		&[aria-label="transaction-list"] {
			thead {
				th {
					padding-left: rfs-value(10px);
					padding-right: rfs-value(10px);
				}
			}

			tbody {
				td {
					padding: rfs-value(14px) rfs-value(10px) rfs-value(22px);
				}
			}
		}

		tbody {
			tr {
				&:not(.expand-row) {
					td {
						background-color: $color-cco-gray-light;
						border-radius: 0;
					}

					td:first-child {
						border-top-left-radius: $border-radius;
						border-bottom-left-radius: $border-radius;
					}
					td:nth-last-child(2) {
						border-bottom-right-radius: $border-radius;
						border-top-right-radius: $border-radius;
					}

					&.bg-blue td {
						background-color: rgba(51, 133, 204, 0.1);
					}

					&.bg-grey td {
						background-color: rgba(154, 165, 177, 0.1);
					}

					&.bg-red td {
						background-color: rgba(224, 0, 88, 0.1);
					}

					&.bg-orange td {
						background-color: rgba(255, 129, 69, 0.1);
					}

					&.bg-green td {
						background-color: rgba(33, 181, 155, 0.1);
					}

					&.bg-rose td {
						background-color: rgba(255, 117, 117, 0.1);
					}
				}

				td {
					padding: rfs-value(16px) rfs-value(20px);
					border: none;
					z-index: 1;

					@include media-breakpoint-down(lg) {
						padding: rfs-value(12px) rfs-value(15px);

						&:first-child {
							border-top-left-radius: $border-radius-sm !important;
							border-bottom-left-radius: $border-radius-sm !important;
						}

						&:nth-last-child(2) {
							border-bottom-right-radius: 0 !important;
							border-top-right-radius: 0 !important;
						}

						&:nth-last-child(1) {
							border-bottom-right-radius: $border-radius-sm !important;
							border-top-right-radius: $border-radius-sm !important;
						}
					}
				}
			}

			.expand-block {
				margin-bottom: rfs-value(8px);
				padding: rfs-value(10px) rfs-value(15px);
				border-radius: $border-radius-sm;
				border: 1px solid $color-fan-gray-title;
			}
		}
	}

	//Rounded color block
	.activity-pattern {
		width: rfs-value(10px);
		height: rfs-value(10px);
		border-radius: 50%;
		background-color: $color-fan-gray-title;

		&.blue {
			background-color: $color-sw-blue;
		}

		&.orange {
			background-color: $color-fan-orange;
		}

		&.rose {
			background-color: $color-fan-rose;
		}

		&.green {
			background-color: $color-cco-green;
		}

		&.red {
			background-color: $color-fan-error;
		}
	}
}