//Adjust popup styles
.popover {
  border: none;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
  min-width: _rem(192);

  &.adjust-popup {
    margin-top: _rem(10);
  }

  .popover-header {
    background-color: $color-cco-white;
    padding: _rem(17) _rem(20) _rem(12) _rem(20);
    border-radius: _rem(8) _rem(8) 0 0;
  }

  .popover-body {
    padding: 0;
    border: none;

    .card {
      border-radius: 0 0 _rem(8) _rem(8);

      .list-group {
        border-radius: 0 0 _rem(8) _rem(8);

        .list-group-item {
          border-color: $color-cco-border;
          border-width: 0 0 1px 0;
          padding: _rem(5) 0 _rem(5) _rem(21);

          &:last-child {
            border-bottom-width: 0;
          }

          .form-control-checkbox {
            margin-bottom: 0;
            display: flex;
            user-select: none;

            .MuiIconButton-root {
              padding: _rem(5);
            }
          }
        }
      }
    }
  }
}