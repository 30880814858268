//Smart category tree styles
.smart-category-tree-container {
  min-height: _rem(685);
  height: 90vh;

  .root-category-node {
    display: flex;
    align-items: center;
    padding-left: _rem(1);
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: _rem(11);
      top: _rem(23);
      width: 1px;
      height: _rem(72);
      background-color: $color-cco-border;
    }

    &.collapsed {
      &:after {
        display: none;
      }
    }
  }

  .toggle-buttons {
    display: flex;
    align-items: center;
    padding: _rem(15) 0 _rem(17) _rem(32);

    .link-button {
      color: $color-cco-placeholder;
      text-decoration: none;

      + .link-button {
        margin-left: _rem(15);
      }

      &:hover {
        color: $color-cco-dark;
      }
    }
  }

  .rstcustom__rowWrapper {
    padding: _rem(8) _rem(10) _rem(7) 0;

    .rstcustom__row.selected {
      .rstcustom__rowContents {
        position: relative;
        border: 1px solid $color-cco-green;
        background-color: rgba(33,181,155,0.1);
        .rstcustom__rowTitle {
          color: $color-cco-green;
          @include fontAvertaSemibold();
        }

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: _rem(10);
          transform: translateY(-50%);
          width: _rem(15);
          height: _rem(16);
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9px' height='15px' viewBox='0 0 9 15'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='SW---UI-style-guideline' transform='translate(-830.000000, -600.000000)' fill='%2321B59B'%3E%3Cg id='Group' transform='translate(830.000000, 600.000000)'%3E%3Ccircle id='Oval' transform='translate(1.500000, 1.500000) rotate(-270.000000) translate(-1.500000, -1.500000) ' cx='1.5' cy='1.5' r='1.5'%3E%3C/circle%3E%3Ccircle id='Oval-Copy-2' transform='translate(1.500000, 7.500000) rotate(-270.000000) translate(-1.500000, -7.500000) ' cx='1.5' cy='7.5' r='1.5'%3E%3C/circle%3E%3Ccircle id='Oval-Copy-3' transform='translate(1.500000, 13.500000) rotate(-270.000000) translate(-1.500000, -13.500000) ' cx='1.5' cy='13.5' r='1.5'%3E%3C/circle%3E%3Ccircle id='Oval' transform='translate(7.500000, 1.500000) rotate(-270.000000) translate(-7.500000, -1.500000) ' cx='7.5' cy='1.5' r='1.5'%3E%3C/circle%3E%3Ccircle id='Oval-Copy-2' transform='translate(7.500000, 7.500000) rotate(-270.000000) translate(-7.500000, -7.500000) ' cx='7.5' cy='7.5' r='1.5'%3E%3C/circle%3E%3Ccircle id='Oval-Copy-3' transform='translate(7.500000, 13.500000) rotate(-270.000000) translate(-7.500000, -13.500000) ' cx='7.5' cy='13.5' r='1.5'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
  }

  .rst__tree {
    margin-left: _rem(-8);
    @include media-breakpoint-down(md) {
      height: _rem(500) !important;
      #smart-category-tree {
        max-height: 80vh;
      }
    }
  }

  .rst__nodeContent {
    right: 0;
    button {
      width: _rem(14);
      height: _rem(14);
      border-radius: _rem(1);
      box-shadow: none;
      border: 1px solid $color-cco-border;

        &.rstcustom__expandButton {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23EDEDED'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'/%3E%3C/svg%3E");
        }

        &.rstcustom__collapseButton {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23EDEDED'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 13H5v-2h14v2z'/%3E%3C/svg%3E");
        }

      &.rstcustom__expandButton,
      &.rstcustom__collapseButton {
        background-size: _rem(16) _rem(16);
        &:hover:not(:active) {
          width: _rem(14);
          height: _rem(14);
          background-size: _rem(16) _rem(16);
        }
      }
    }

    .rstcustom__row.add-new {
      cursor: pointer;

      .rstcustom__rowContents {
        color: $color-cco-green;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          right: _rem(-5);
          top: _rem(12);
          width: _rem(30);
          height: _rem(30);
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10px' height='10px' viewBox='0 0 10 10'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='SW---UI-style-guideline_icons' transform='translate(-1122.000000, -381.000000)' fill='%2321B59B' fill-rule='nonzero'%3E%3Cg id='Path-3-Copy-2' transform='translate(1122.000000, 381.000000)'%3E%3Cpolygon id='Path' points='5.62390158 4.37609842 5.62390158 0 4.37609842 0 4.37609842 4.37609842 0 4.37609842 0 5.62390158 4.37609842 5.62390158 4.37609842 10 5.62390158 10 5.62390158 5.62390158 10 5.62390158 10 4.37609842'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
  }

  .rstcustom__rowTitle {
    @include fontAvertaRegular();
    font-size: _rem(14);
  }

  .rstcustom__rowContents {
    box-shadow: none;
    border: 1px solid $color-cco-border;
    border-radius: _rem(4);
    padding: _rem(10) _rem(29);
    min-height: _rem(35);

    @include media-breakpoint-down(sm) {
      padding: _rem(10) _rem(19);
    }
  }

  .rst__lineHalfHorizontalRight::before,
  .rst__lineHalfHorizontalRight::after {
    background-color: $color-cco-border;
  }

  .rst__lineFullVertical::after {
    background-color: $color-cco-border;
  }

  .rstcustom__lineChildren::after {
    background-color: $color-cco-border;
  }
}