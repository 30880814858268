/* Toggle button switcher */
.toggle-button {
  display: flex;
  align-items: center;

  label.switcher {
    position: relative;
    width: _rem(50);
    height: _rem(20);
    margin-bottom: 0;

    //checked
    input[type="checkbox"] {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: $color-cco-green;

        &:before {
          transform: translateX(30px);
        }

        &:after {
          content: attr(data-checked);
          position: absolute;
          left: 0;
          top: _rem(1);
          transform: translateX(50%);
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 0.625rem;
      background-color: $color-cco-placeholder;
      transition: all ease .4s;
      border-radius: 2.5rem;
      line-height: 1.563rem;

      &:after {
        position: absolute;
        content: attr(data-unchecked);
        top: _rem(1);
        left: _rem(22);
        color: $color-cco-white;
        font-size: _rem(9);
        line-height: _rem(20);
        text-transform: uppercase;
        @include fontAvertaSemibold();
      }

      &:before {
        position: absolute;
        content: "";
        width: 1rem;
        height: 1rem;
        top: _rem(2);
        left: _rem(2);
        background-color: $white;
        border-radius: 50%;
        transition: all ease .4s;
      }
    }
  }
}