/* Authorization pages styles */
.auth {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 _rem(10);

  @include media-breakpoint-down(md) {
    background-color: $color-cco-dark;
  }

  .form-wrapper {
    padding: _rem(50) _rem(30) _rem(10) _rem(30);
    margin: auto 0;
    width: _rem(400);
    background-color: $color-cco-dark;
    border-radius: _rem(10);

    @include media-breakpoint-down(md) {
      width: 100%;
      padding: _rem(50) _rem(15) _rem(10) _rem(15);
    }

    .MuiInput-underline {
      // remove input border bottom, which is from .MuiInput-underline :before and :after
      &:before, &:after {
        display: none;
      }
    }

    .logo-wrapper {
      padding-bottom: _rem(45);
    }

    .form-control-label {
      color: $color-cco-white;
    }

    .form-control-lg {
      height: auto;
      border-radius: _rem(4);
      padding: _rem(7) _rem(15);

      .MuiFormHelperText-root {
        display: none;
      }
    }

    .checkbox-row {
      @include media-breakpoint-up(lg) {
        margin-left: -14px;
      }
    }

    .actions-wrapper {
      padding: _rem(15) 0 _rem(30) 0;
      display: flex;
      justify-content: center;

      .MuiButtonBase-root {
        min-width: _rem(120);
        justify-content: center;
      }
    }
  }
}