.inner_page.fa2 {
  display: flex;
  align-items: center;
  min-height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $gray-light-bg;

  .fa2-title {
    font-size: 2.333rem;
    @include gothamBoldAlternative;
    margin-top: 2.666rem;
    margin-bottom: 3.333rem;
    line-height: 1.28;
    color: $color-dark;
    @include fp-media-down-lg {
      margin-bottom: 1.333rem;
    }
  }

  .fa2-subtitle {
    font-size: _rem(20);
    @include fontAvertaSemibold;
    color: map-get($theme-colors, fp-primary);
    margin-bottom: 0.333rem;
    line-height: normal;
  }

  .fa2-step {
    text-align: start;
    padding: 2rem;
    border-radius: 1.333rem;
    background-color: $white;
    color: $text-dark;
    @include fp-media-down-lg {
      border-radius: 0.8rem;
      padding: 1rem;
    }

    .fa2-step-header {
      font-size: 1.333rem;
      line-height: normal;
      margin-bottom: 0.333rem;
      @include fontAvertaSemibold;
    }

    .fa2-step-text {
      font-size: 1.006rem;
      @include fontAverta;
      line-height: normal;
      margin-bottom: 0;
    }

    .fa2-step-img {
      @include fp-media-down-lg {
        margin-top: _rem(10);
      }
    }
  }

  .fa2-step + .fa2-step {
    margin-top: 1.333rem;
  }

  .fa2-btn-action {
    color: $white;
    background-color: map-get($theme-colors, fp-primary);
    font-size: 0.8rem;
    @include gothamBoldAlternative;
    border-radius: 0.2666rem;
    padding: 0.6rem 2rem;
    min-width: 13rem;
    @include fp-media-down-lg {
      width: 100%;
      min-width: unset;
    }

    &.secondary {
      background-color: map-get($theme-colors, text-secondary);
    }
  }

  .fa2-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 41.466rem;
    margin-bottom: 3.333rem;
    @include fp-media-down-lg {
      margin-bottom: 1.333rem;
    }

    &.verify {
      .fa2-title {
        margin-top: _rem(90);
        margin-bottom: _rem(10);
        @include fp-media-down-lg {
          margin-top: _rem(50);
        }
      }

      .fa2-label {
        font-size: _rem(18);
        @include fontGothamNormal;
        color: $color-dark;
        margin-bottom: 0;
      }

      .fields-block {
        padding: _rem(50) 0 _rem(42);
      }

      .input-code-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .separator {
          position: relative;
          margin: 0 _rem(21);
          color: #aeaeae;
          font-size: 1.25rem;

          @media (max-width: 600px) {
            margin: 0 0.5rem;
            font-size: 0.625rem;
          }
        }

        .parts {
          display: flex;
          align-items: center;
          gap: _rem(10);

          .form-control {
            text-align: center;
            font-size: _rem(35);
            @include gothamBoldAlternative;
            color: $text-dark;
            border: 1px solid $date-picker-border;
            border-radius: _rem(3.5);
            padding: _rem(9) _rem(8);
            width: _rem(80);
            height: _rem(100);

            &:focus {
              box-shadow: none;
            }

            @include fp-media-down-lg {
              font-size: 1.5rem;
              height: 3.5rem;
              width: auto;
              padding: _rem(9) _rem(10);
            }
          }
        }

        &.has-error {
          .code-input {
            border-color: map-get($theme-colors, fp-primary);
            background-color: $white;
          }
        }
      }

      .errors-block {
        min-height: _rem(45);
        margin-top: _rem(15);
      }

      .err-msg {
        font-size: _rem(16);
        @include fontGothamNormal;
        color: map-get($theme-colors, fp-error);
        line-height: _rem(45);
        margin-bottom: 0;
      }

      .checkbox-container {
        margin-top: 0;
        margin-bottom: _rem(52);
        font-size: _rem(16);
        @include fontGothamNormal;
        color: map-get($theme-colors, text-primary);
        line-height: normal;
        @include fp-media-down-lg {
          margin-bottom: _rem(25);
        }
      }

      .btn-group-actions {
        display: flex;
        align-items: center;
        gap: _rem(10);
        justify-content: center;

        @include fp-media-down-lg {
          align-items: stretch;
          width: 100%;
        }
      }
    }
  }

  .btn {
    text-transform: uppercase;
  }
}
